svc-question {
  display: block;
  position: absolute;
  top: 1px;
  left: 1px;
  right: 1px;
  bottom: 1px; }

.svc-question__adorner {
  position: relative;
  height: 100%;
  width: 100%; }
  .svc-question__adorner .sv-action-bar {
    overflow: visible; }
    .svc-question__adorner .sv-action-bar .sv-action:not(:first-child) .sv-action-bar-item {
      border: none; }
      .svc-question__adorner .sv-action-bar .sv-action:not(:first-child) .sv-action-bar-item:active {
        opacity: 0.5;
        background-color: var(--background-dim, #f3f3f3); }

.svc-question__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: calc(0.5 * var(--base-unit, 8px));
  box-sizing: border-box;
  padding: calc(4 * var(--base-unit, 8px)) calc(5 * var(--base-unit, 8px)) calc(8 * var(--base-unit, 8px));
  background: var(--background, #fff);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  border: 2px solid transparent;
  outline: none; }

.svc-question__content .sd-multipletext__item-title {
  color: var(--foreground, #161616); }

.svc-question__content .sd-multipletext__item-container.sd-input:focus-within {
  box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.15); }

.svc-question__content .sd-matrixdynamic__btn.sd-matrixdynamic__add-btn {
  color: var(--foreground-disabled, rgba(22, 22, 22, 0.25)); }

.svc-question__content > div {
  overflow: visible; }

.svc-panel__placeholder {
  color: var(--foreground-light, #909090); }

.svc-question__content-actions {
  position: absolute;
  display: none;
  bottom: calc(2 * var(--base-unit, 8px));
  inset-inline-start: calc(4 * var(--base-unit, 8px));
  inset-inline-end: calc(3.5 * var(--base-unit, 8px)); }
  .svc-question__content-actions .sv-action--convertTo {
    min-width: calc(13 * var(--base-unit, 8px));
    max-width: max-content;
    margin-inline-end: auto; }
    .svc-question__content-actions .sv-action--convertTo .sv-action-bar-item {
      max-width: 100%;
      flex-direction: row-reverse; }
    .svc-question__content-actions .sv-action--convertTo .sv-action-bar-item__title {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
      justify-content: left; }
    .svc-question__content-actions .sv-action--convertTo .sv-action-bar-item__title--with-icon {
      margin-inline-start: 0;
      margin-inline-end: calc(0.5 * var(--base-unit, 8px)); }

.svc-question__content:focus,
.svc-creator .svc-page .svc-hovered > .svc-question__content {
  box-shadow: 0 0 0 2px var(--secondary-light, rgba(255, 152, 20, 0.25)); }

.svc-creator .svc-page .svc-question__content--selected:not(.svc-question__content--dragged).svc-question__content--selected,
.svc-creator .svc-page .svc-hovered > .svc-question__content:not(.svc-question__content--dragged).svc-question__content--selected {
  box-shadow: 0 0 0 2px var(--secondary, #ff9814); }

.svc-creator .svc-page .svc-question__content--selected:not(.svc-question__content--dragged) > .svc-question__content-actions,
.svc-creator .svc-page .svc-hovered > .svc-question__content:not(.svc-question__content--dragged) > .svc-question__content-actions {
  display: block; }

.svc-question__content--dragged {
  background: var(--background-for-editors, #f9f9f9); }
  .svc-question__content--dragged .sd-question__title {
    color: var(--foreground-light, #909090); }
  .svc-question__content--dragged.svc-question__content--selected .svc-question__content-actions {
    display: none; }

.svc-question__content .sd-question.sd-question--table {
  margin-top: calc(-1 * var(--base-unit, 8px));
  padding-top: calc(1 * var(--base-unit, 8px)); }

.svc-dragged-element-shortcut {
  min-width: 100px;
  height: calc(3 * var(--base-unit, 8px));
  border-radius: calc(12.5 * var(--base-unit, 8px));
  background-color: var(--background, #fff);
  color: var(--foreground, #161616);
  padding: calc(1 * var(--base-unit, 8px)) calc(1.5 * var(--base-unit, 8px));
  cursor: grabbing;
  position: absolute;
  z-index: 1000;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.svc-dragged-element-shortcut .sv-svg-icon use {
  fill: var(--primary, #19b394); }

.svc-dragged-element-shortcut--selected {
  border: 2px solid var(--secondary, #ff9814); }

.svc-dragged-element-shortcut__text {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 600;
  font-size: calc(1.5 * var(--base-unit, 8px));
  line-height: calc(2 * var(--base-unit, 8px));
  margin-left: calc(4 * var(--base-unit, 8px));
  line-height: calc(3 * var(--base-unit, 8px)); }

.svc-dragged-element-shortcut__icon {
  position: absolute;
  width: calc(3 * var(--base-unit, 8px));
  height: calc(3 * var(--base-unit, 8px)); }

.svc-question__content--drag-over-inside .svc-panel__placeholder_frame,
.svc-hovered > .svc-question__content--drag-over-inside .svc-panel__placeholder_frame {
  box-shadow: 0 0 0 2px var(--primary, #19b394);
  background: var(--primary-light, rgba(25, 179, 148, 0.1)); }
  .svc-question__content--drag-over-inside .svc-panel__placeholder_frame > .svc-question__content-actions,
  .svc-hovered > .svc-question__content--drag-over-inside .svc-panel__placeholder_frame > .svc-question__content-actions {
    display: block; }

.svc-question__content--drag-over-left:before {
  content: " ";
  position: absolute;
  left: -7px;
  background: var(--secondary, #ff9814);
  top: 0;
  height: 100%;
  width: 4px; }

.svc-question__content--drag-over-right:after {
  content: " ";
  position: absolute;
  right: -7px;
  background: var(--secondary, #ff9814);
  top: 0;
  height: 100%;
  width: 4px; }

.svc-question__content-actions .sv-action-bar {
  box-sizing: border-box;
  padding: 0;
  justify-content: flex-end; }

svc-question sv-action-bar,
svc-question .sv-action-bar,
.svc-question sv-action-bar,
.svc-question .sv-action-bar {
  padding: 0; }

.svc-panel__edge {
  position: absolute;
  border: 1px solid orange;
  height: 10px;
  width: 100%; }

.svc-panel__edge--top {
  top: 0; }

.svc-panel__edge--bottom {
  bottom: 0; }

.svc-question__adorner--start-with-new-line[data-sv-drop-target-survey-element="sv-drag-drop-ghost-survey-element-name"] {
  height: 100%;
  overflow: hidden;
  min-width: 4px; }
  .svc-question__adorner--start-with-new-line[data-sv-drop-target-survey-element="sv-drag-drop-ghost-survey-element-name"] .svc-question__content.svc-question__content.svc-question__content {
    border: 0;
    padding: 0; }

[data-sv-drop-target-survey-element="sv-drag-drop-ghost-survey-element-name"] {
  border: none;
  height: 0; }
  [data-sv-drop-target-survey-element="sv-drag-drop-ghost-survey-element-name"] .svc-question__drag-area {
    display: none; }
  [data-sv-drop-target-survey-element="sv-drag-drop-ghost-survey-element-name"] .svc-question__drag-element {
    display: none; }
  [data-sv-drop-target-survey-element="sv-drag-drop-ghost-survey-element-name"] .svc-question__content {
    background: transparent;
    box-shadow: none; }

.svc-hovered > .svc-question__content > .svc-question__drag-area {
  visibility: visible; }

.svc-question__content.svc-question__content--selected > .svc-question__drag-area {
  visibility: visible;
  z-index: 1; }

.svc-question__drag-area {
  position: absolute;
  cursor: move;
  top: 0;
  left: 0;
  visibility: hidden;
  height: calc(3.5 * var(--base-unit, 8px));
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

.svc-question__drag-element {
  width: 100%;
  height: calc(4 * var(--base-unit, 8px));
  opacity: 0.5; }
  .svc-question__drag-element use {
    fill: var(--foreground-light, #909090); }

.sd-panel .svc-question__content,
.sd-row--multiple .svc-question__content {
  border-radius: 0; }
  .sd-panel .svc-question__content.svc-question__content--selected,
  .sd-row--multiple .svc-question__content.svc-question__content--selected {
    border: 1px solid transparent;
    box-shadow: none; }
  .sd-panel .svc-question__content:hover,
  .sd-row--multiple .svc-question__content:hover {
    border: 1px solid transparent;
    box-shadow: none; }
  .sd-panel .svc-question__content:not(.svc-question__content--selected):not(:hover),
  .sd-row--multiple .svc-question__content:not(.svc-question__content--selected):not(:hover) {
    border: 1px dashed var(--border, #d6d6d6);
    box-shadow: none; }

.sd-row--multiple .svc-hovered > .svc-question__content:not(.svc-question__content--selected) {
  border: 1px solid var(--secondary-light, rgba(255, 152, 20, 0.25));
  box-shadow: 0 0 0 1px var(--secondary-light, rgba(255, 152, 20, 0.25)); }

.sd-panel .svc-row--ghost.svc-row--ghost .svc-question__content.svc-question__content {
  border: none; }

.svc-widget__content {
  width: 100%; }
  .svc-widget__content .sd-question__content {
    pointer-events: none; }

.svc-question__content .sd-paneldynamic__footer,
.svc-question__content .sd-paneldynamic__separator {
  display: none; }

.svc-panel__placeholder_frame {
  border: 1px dashed var(--border, #d6d6d6);
  width: 100%;
  box-sizing: border-box;
  height: calc(30 * var(--base-unit, 8px));
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: calc(2 * var(--base-unit, 8px)) calc(8 * var(--base-unit, 8px)); }

.svc-panel__add-new-question-container {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative; }

.svc-panel__add-new-question {
  align-items: center;
  margin-top: calc(2 * var(--base-unit, 8px));
  margin-left: 0;
  margin-right: 0;
  width: auto;
  height: calc(5 * var(--base-unit, 8px));
  line-height: calc(5 * var(--base-unit, 8px));
  vertical-align: baseline;
  text-align: center;
  background-color: var(--background, #fff);
  cursor: pointer;
  user-select: none;
  border: none;
  box-shadow: none; }
  .svc-panel__add-new-question:hover {
    outline: none; }
  .svc-panel__add-new-question .svc-text {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: center;
    margin: calc(1 * var(--base-unit, 8px)) calc(3 * var(--base-unit, 8px));
    line-height: calc(2 * var(--base-unit, 8px));
    color: var(--primary, #19b394); }

.svc-panel__question-type-selector {
  appearance: none;
  display: flex;
  height: calc(5 * var(--base-unit, 8px));
  padding: calc(1 * var(--base-unit, 8px));
  box-sizing: border-box;
  border: none;
  border-radius: 2px;
  background-color: transparent;
  cursor: pointer;
  margin-right: calc(1 * var(--base-unit, 8px));
  top: calc(2 * var(--base-unit, 8px));
  margin: 0;
  position: absolute;
  right: 0; }
  .svc-panel__question-type-selector use {
    fill: var(--foreground-light, #909090); }
  .svc-panel__question-type-selector:hover {
    background-color: var(--background-dim, #f3f3f3); }

.sd-panel .svc-row {
  margin-top: calc(1 * var(--base-unit, 8px)); }

.sd-panel .svc-row:first-of-type {
  margin-top: 0; }

.sd-panel .svc-row .sd-row {
  margin-top: 0;
  margin-bottom: 0; }

.sd-panel .svc-row.svc-row--ghost {
  margin-top: calc(1 * var(--base-unit, 8px));
  margin-bottom: calc(1.5 * var(--base-unit, 8px)); }
  .sd-panel .svc-row.svc-row--ghost .sv-drag-drop-ghost {
    top: 0; }
